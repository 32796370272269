import supportLayout from "../../layout/support"


const supportRoutes = [
    {
        path: "/support-*",
        name: "support_index",
        meta: {
            module: "shop",
            backgroundColor: "#fff"
        },
        component: () => import("@/views/support/index")
    },
    {
        path: "/support_list",
        name: "support_list",
        meta: {
            module: "shop"
        },
        component: () => import("@/views/support/list")
    }
]
export default {
    path: "/shop",
    component: supportLayout,
    redirect: "/street",
    name: "shopIndex",
    children: [...supportRoutes]
}
