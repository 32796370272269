import http from "../../utils/http"

/**
 * 获取个人注册协议
 */
export function getRegisiterAggrement(params) {
    return http({
        url: "/api/register/aggrement",
        data: params
    })
}

/**
 * 获取单位注册协议 luoll
 */
export function getRegisiterAggrementCompany(params) {
    return http({
        url: "/api/register/aggrementCompany",
        data: params
    })
}

/**
 * 获取隐私协议 luoll
 */
export function getRegisiterAggrementPrivacy(params) {
    return http({
        url: "/api/register/aggrementPrivacy",
        data: params
    })
}

/**
 * 获取维权中心保障协议 zhanghf
 */
export function getRegisiterAggrementSafegus(params) {
    return http({
        url: "/api/register/aggrementSafegus",
        data: params
    })
}

/**
 * 注册
 */
export function register(params) {
    return http({
        url: "/api/register/username",
        data: params
    })
}
/**
 * 手机号注册
 */
export function registerMobile(params) {
    return http({
        url: "/api/register/mobile",
        data: params
    })
}

/**
 * 注册配置
 */
export function registerConfig(params) {
    return http({
        url: "/api/register/config",
        data: params
    })
}
/**
 *  发送验证码
 */
export function registerMobileCode(params) {
    return http({
        url: "/api/register/mobileCode",
        data: params
    })
}
/**
 *  获取地址
 */
export function registerAddressLists(params) {
    return http({
        url: "/api/address/lists",
        data: params
    })
}
