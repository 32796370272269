import notnavLayout from "../../layout/notnav"

// 城市分站与本社生鲜相关路由
const notnavRoutes = [
	{
		path: "/ourfresh",
		name: "ourfresh",
		meta: {
			module: "shop",
			backgroundColor: "#fff",
			title: '本社生鲜'
		},
		component: () => import("@/views/notnav/ourfresh/index")
	},
	{
		path: "/citylist",
		name: "citylist",
		meta: {
			module: "goods",
			title: '城市分站商品列表',
			backgroundColor: "#F7F7F7"
		},
		component: () => import("@/views/notnav/city/index")
	},
	{
		path: "/special",
		name: "special",
		meta: {
			module: "special",
			title: '专馆页',
			backgroundColor: "#F7F7F7"
		},
		component: () => import("@/views/notnav/special/index")
	},
]

// 会员模块
export default {
	path: "/notnav",
	component: notnavLayout,
	redirect: "/notnav",
	name: "notnavIndex",
	children:
		[...notnavRoutes]
}
