import http from "../../utils/http"
import config from "../../utils/config"
/**
 * 获取会员基础信息
 */
export function memberInfo(params) {
    return http({
        url: "/api/member/info",
        data: params,
        forceLogin: true
    })
}
/**
 * 获取单位详情
 */
export function memberOrgDetail(params) {
    return http({
        url: "/fupin/api/organization/orgDetail",
        data: params,
        forceLogin: true
    })
}
/**
 * 获取单位类型接口
 */
export function memberTypeList(params) {
    return http({
        url: "/fupin/api/organization/typeList",
        data: params,
        forceLogin: false
    })
}
/**
 * 保存单位详情
 */
export function memberSaveOrgInfo(params) {
    return http({
        url: "/fupin/api/organization/saveOrgInfo",
        data: params,
        forceLogin: true
    })
}
/**
 * 成员管理列表
 */
export function memberList(params) {
    return http({
        url: "/fupin/api/organization/memberList",
        data: params,
        forceLogin: true
    })
}
/**
 * 成员管理新增
 */
export function memberAdd(params) {
    return http({
        url: "/fupin/api/organization/memberAdd",
        data: params,
        forceLogin: true
    })
}
/**
 * 成员管理删除
 */
export function memberKick(params) {
    return http({
        url: "/fupin/api/organization/memberKick",
        data: params,
        forceLogin: true
    })
}
/**
 * 订单数量
 */
export function orderNum(params) {
    return http({
        url: "/api/order/num",
        data: params,
        forceLogin: true
    })
}

/**
 * 优惠券数量
 */
export function couponNum(params) {
    return http({
        url: "/api/member/couponnum",
        data: params,
        forceLogin: true
    })
}

/**
 * 我的足迹
 */
export function footprint(params) {
    return http({
        url: "/api/goodsbrowse/page",
        data: params,
        forceLogin: true
    })
}
/**
 * 会员等级列表
 */
export function levelList(params) {
    return http({
        url: "/api/memberlevel/lists",
        data: params,
        forceLogin: true
    })
}
/**
 * 导出成员管理
 */
export function exportExcl(params) {
    return http({
        url: "/fupin/api/organization/exportExcl",
        data: params,
        forceLogin: true
    })
}


/**
 * 导入成员
 */
export function importMember_pc(params) {
    return http({
        url: "/fupin/api/organization/importMember_pc",
        data: params,
        forceLogin: true
    })
}