import http from "../../utils/http"
import Cookies from 'js-cookie'

/**
 * 登录
 */
export function login (params) {
    let website_id = Cookies.get('website_id');
    let website_type = Cookies.get('website_type');
    if (website_id && website_type) {
        params = {
            ...params,
            website_id: website_id,
            website_type: website_type
        }
    }
    return http({
        url: "/api/login/login",
        data: params
    })
}

/**
 * 手机号登录
 * @param json params
 */
export function mobile_login(params) {
    let website_id = Cookies.get('website_id');
    let website_type = Cookies.get('website_type');
    if (website_id && website_type) {
        params = {
            ...params,
            website_id: website_id,
            website_type: website_type
        }
    }
    return http({
        url: "/api/login/mobile",
        data: params
    })
}

/**
 * 获取短信动态码
 */
export function mobileCode(params) {
    return http({
        url: "/api/login/mobileCode",
        data: params
    })
}
export function loginCaptcha(params) {
    return http({
        url: "/api/login/login_captcha",
        data: params
    },10000,"post")
}

/**
 * 重置密码
 */
export function rePass(params) {
    return http({
        url: "/api/findpassword/mobile",
        data: params
    })
}

/**
 * 下一步
 */
export function nextStep(params) {
    return http(
        {
            url: "/api/member/checkmobile",
            data: params
        },
        -1
    )
}

/**
 * 获取短信动态码
 */
export function smsCode(params) {
    return http({
        url: "/api/findpassword/mobilecode",
        data: params
    })
}

//手机动态验证码校验
export function checkedCode(params) {
	return http({
		url: "/api/findpassword/mobilecodechecked",
		data: params
	});
}

/**
 * 获取注册配置
 */
export function registerConfig(params) {
    return http({
        url: "/api/register/config",
        data: params
    })
}

/**
 * 是否有待激活消费券
 */
export function waitActivate(params) {
    return http({
        url: "/fupin/api/Voucher/waitActivate",
        data: params
    })
}
