<template>
	<div class="header">
		<ns-header-top />
		<ns-header-mid />
    </div>
</template>

<script>
    import NsHeaderTop from "./NsHeaderTop"
    import NsHeaderMid from "./NsHeaderMid"
	import {mapGetters} from "vuex";

    export default {
        props: {
            forceExpand: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isShopHover: false,
                isIndex: false,
                thisRoute: "",
                goodsCategoryTree: [],
                selectedCategory: -1,
                navList: [],
                navSelect: "",
            }
        },
        components: {
            NsHeaderTop,
            NsHeaderMid
        },
        computed: {
			...mapGetters(['defaultShopImage'])
		},
        beforeCreate() {
        },
        created() {

            this.$store.dispatch("cart/cart_count")
            if (this.$route.path == "/" || this.$route.path == "/index") this.isIndex = true
        },
        mounted() {
        },
        watch: {
			/*$route(curr) {
				this.$store.dispatch('cart/cart_count');
				this.getTree();
				if (this.$route.path == '/' || this.$route.path == '/index') this.isIndex = true;
				this.getShopInfo();
			}*/
        },
        methods: {
        }
    }
</script>

<style scoped lang="scss">
#app{
	background-color: #f7f7f7;
}
#app .el-header{
	width: 100%;
	height: 145px !important;
	background-color: #fff;
}
#app .header {
	width: 100%;
	height: 145px !important;
	background-color: #fff;

	.shadow {
		box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
	}
}
</style>
