<template>
    <div class="advantage">
        <div>
            <img src="@/assets/images/index_icon1.png" alt="">
        </div>
        <img src="@/assets/images/icon_line.png" alt="">
        <div>
            <img src="@/assets/images/index_icon2.png" alt="">
        </div>
        <img src="@/assets/images/icon_line.png" alt="">
        <div>
            <img src="@/assets/images/index_icon3.png" alt="">
        </div>
        <img src="@/assets/images/icon_line.png" alt="">
        <div>
            <img src="@/assets/images/index_icon4.png" alt="">
        </div>
    </div>
</template>

<script>

    export default {
        name: "advantage",
        data() {
          return{
              shopServiceList: []
          }
        },
        mounted() {
        },
        methods:{
        }
    }
</script>

<style scoped type="text/scss" lang="scss">
    .advantage{
      display: flex;
      align-items: center;
      >div{
        padding: 10px 75px;
      }
    }
</style>
