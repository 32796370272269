<template>
    <div class="newFooter">
        <div class="newFooter_con">
            <div class="newFooter_friendship" v-if="linkList.length > 0">
                <div class="newFooter_friendship_title">
                    <span>
                        友情链接
                    </span>
                </div>
                <div class="newFooter_friendship_list">
                    <div class="link-item" v-for="(link_item, link_index) in linkList" :key="link_index"
                         :title="link_item.link_title">
                        <span @click="linkUrl(link_item.link_url, link_item.is_blank)"><img
                                :src="$img(link_item.link_pic)" /></span>
                    </div>
                </div>
            </div>
            <div class="footer-center">
                <div class="center" v-if="helpList.length">
                    <div class="help" v-for="(item, index) in helpList" :key="index">
                        <p @click="clickToHelp(item.class_id)">{{ item.class_name }}</p>
                        <ul class="help-ul">
                            <li class="help-li" v-for="(helpItem, helpIndex) in item.child_list" :key="helpIndex" @click="clickToHelpDetail(helpItem.id)">{{ helpItem.title }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <CopyRight></CopyRight>

    </div>
</template>

<script>
    import advantage from "@/views/index/components/advantage.vue"
    import { copyRight, shopServiceLists, friendlyLink } from "@/api/website"
    import { mapGetters } from "vuex"
    import { helpList } from "@/api/cms/help"
    import newCopyRight from "./newCopyRight"
    import CopyRight from "./CopyRight"

    export default {
        name: "newFooter",
        components: {
            advantage,newCopyRight,CopyRight
        },
        computed: {
            ...mapGetters(["siteInfo", "wapQrcode"]),
            qrcode: function() {
                return this.wapQrcode === "" ? "" : this.wapQrcode.path.weapp.img
            }
        },
        data() {
            return {
                linkList: [],
                helpList: [],
            }
        },
        mounted() {
            this.link()
            this.getHelpList()
        },
        methods: {
            /**
             * 图片加载失败
             */
            imageError() {
                this.siteInfo.web_qrcode = "../../assets/images/wxewm.jpg"
            },
            clickToHelpDetail(id) {
                // this.$router.push({ path: "/cms/help-" + id });

                let routeData = this.$router.resolve({ path: '/cms/help-'+ id });
                window.open(routeData.href, '_blank');
            },
            /**
             * 获取帮助列表
             */
            getHelpList() {
                helpList()
                    .then(res => {
                        if (res.code == 0 && res.data) {
                            var arr = [];
                            arr = res.data.slice(0, 5)

                            for (let i=0; i<arr.length; i++) {
                                arr[i].child_list = arr[i].child_list.slice(0, 3);
                            }

                            this.helpList = arr
                        }
                    })
                    .catch(err => {})
            },
            link() {
                friendlyLink({})
                    .then(res => {
                        if (res.code == 0 && res.data) {
                            this.linkList = res.data.list
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.message)
                    })
            },
            linkUrl(url, target) {
                if (!url) return
                if (url.indexOf("http") == -1) {
                    if (target) this.$router.pushToTab({ path: url })
                    else this.$router.push({ path: url })
                } else {
                    if (target) window.open(url)
                    else window.location.href = url
                }
            }
        }
    }
</script>

<style scoped type="text/scss" lang="scss">
    .footer-center {
        width: $width;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding: 0px 0;
        .left {
            width: 300px;

            .left-phone {
                font-size: 26px;
                font-weight: 600;
                color: $base-color;
                padding-bottom: 10px;
                border-bottom: 1px solid $base-color;
                margin-bottom: 12px;
            }
            .left-email {
                font-size: 18px;
                color: #838383;
            }
        }
        .center {
            /*width: calc(100% - 450px);*/
            width: 100%;
            padding: 0;
            display: flex;
            margin: 0 auto;
            text-align: center;

            .help{
                width: 20%;
                border-right:1px solid #eee;
                p {
                    font-size: 18px;
                    font-weight: 600;
                    color: #838383;
                    margin-bottom:0px;
                    cursor: pointer;
                }

                .help-li {
                    font-size: 14px;
                    color: #838383;
                    line-height: 30px;
                    cursor: pointer;
                    min-width: 168px;
                }

                p:hover, .help-li:hover {
                    color: $base-color;
                }
            }
            .help:last-child{
                border-right: none;
            }

        }
        .right {
            width: 250px;
            text-align: center;

            .el-image {
                width: 120px;
                height: 120px;
                line-height: 120px;
                text-align: center;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            p {
                font-size: 18px;
                color: #838383;
            }
        }

        .qrcode-hide {
            display: none;
        }
    }
    .newFooter {
        background-color: #FFFFFF;
        border-top: 1px solid $base-color;

        &_advantage {
            margin-bottom: 20px;
        }

        &_con {
            padding: 10px 5px;
            width: 1210px;
            margin: 20px auto;
            margin-bottom: 0;
        }

        &_friendship {
            border: 1px solid #e9e9e9;
            margin-bottom: 20px;

            &_list {
                display: flex;
                padding: 20px 0;
                padding-left: 20px;


                > div {
                    width: 150px;
                    margin-right: 10px;
                    >span{
                        height: 80px;
                        cursor: pointer;
                        display: flex;
                        overflow: hidden;
                        align-items: start;
                    }
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            &_title {
                height: 50px;
                line-height: 50px;
                box-sizing: border-box;
                padding: 0 20px;
                border-bottom: 1px solid #e9e9e9;

                span {
                    display: inline-block;
                    height: 50px;
                    color: #ff547b;
                    border-bottom: 1px solid #ff547b;
                    padding: 0 20px;
                }
            }
        }
    }
</style>
