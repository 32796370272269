import DiyBasicLayout from "../../layout/diybasic"

// 其他模块
export default {
	path: "/",
	component: DiyBasicLayout,
	redirect: "/index",
	name: "Index",
	children: [	{
		path: "/promotion/pcdiy",
		name: "pcdiy",
		meta: {
			module: "pcdiy",
			title: '微页面',
			backgroundColor: "#F7F7F7"
		},
		component: () => import("@/views/promotion/pcdiy/index")
	}]
}
