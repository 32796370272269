import http from "../../utils/http"
import Cookies from 'js-cookie'

//region************************* goods end  ***************************************/
/**
 * 商品列表
 * @param {object} params
 */
export function goodsSkuPage (params) {
    let website_id = Cookies.get('website_id');
    let website_type = Cookies.get('website_type');
    if (website_id && website_type) {
        params = {
            ...params,
            website_id: website_id,
            website_type: website_type
        }
    }
    return http({
        url: "/api/goodssku/page2_0",
        data: params
    })
}

export function goodsSkuList (params) {
    return http({
        url: "/api/goodssku/lists",
        data: params
    })
}

/**
 * 商品详情
 * @param {Object} params
 */
export function goodsSkuDetail (params) {
    return http({
        url: "/api/goodssku/detail",
        data: params
    })
}

/**
 * 商品信息
 * @param { Object } params
 */
export function goodsSkuInfo (params) {
    return http({
        url: "/api/goodssku/info",
        data: params
    })
}

/**
 * 商品信息
 * @param { Object } params
 */
export function goodsQrcode (params) {
    return http({
        url: "/api/goodssku/goodsqrcode",
        data: params
    })
}

/**
 * 获取满减信息
 * @param {Object} params
 */
export function manjian (params) {
    return http({
        url: "/manjian/api/manjian/info",
        data: params
    })
}

/**
 * 获取售后服务
 * @param {Object} params
 */
export function aftersale (params) {
    return http({
        url: "/api/goods/aftersale",
        data: params
    })
}

/**
 * 更新商品点击量
 * @param {Object} params
 */
export function modifyClicks (params) {
    return http({
        url: "/api/goods/modifyclicks",
        data: params
    })
}

/**
 * 添加商品足迹
 * @param {Object} params
 */
export function addGoodsbrowse (params) {
    return http({
        url: "/api/goodsbrowse/add",
        data: params
    })
}

/**
 * 商品推荐列表
 * @param {object} params
 */
export function goodsRecommend (params) {
    return http({
        url: "/api/goodssku/recommend",
        data: params
    })
}
/**
 * 品牌列表
 */
export function brandList (params) {
    return http({
        url: "/api/goodsbrand/page",
        data: params
    })
}

//endregion************************* goods end  ***************************************/

//region************************* 城市分站 start  ***************************************/
/**
 * 城市分站banner luolili
 */
export function cityBannerList (params) {
    return http({
        url: "/city/api/WebsiteBanner/bannerList",
        data: params
    })
}
/**
 * 城市分站详情 luolili
 */
export function websiteDetail (params) {
    return http({
        url: "/city/api/Website/websiteDetail",
        data: params
    })
}
/**
 * 根据分站域名，获取分站详情 zhh
 * @param {*} params 
 * @returns 
 */
export function onHostWebsiteDetail (params) {
    return http({
        url: "/city/api/Website/onHostDetail",
        data: params
    })
}
/**
 * 城市分站推荐 luolili
 */
export function recommendGoodsList (params) {
    return http({
        url: "/city/api/WebsiteRecommendGoods/recommendGoodsList?site_id=" + params.site_id + "&website_type=" + params.website_type,
    })
}
/**
 * 城市分站集合 luolili
 */
export function websiteList (params) {
    return http({
        url: "/city/api/Website/websiteList",
        data: params
    })
}
//endregion************************* 城市分站 end  ***************************************/

//region************************* 本社生鲜 start  ***************************************/
/**
 * 本社生鲜供应商集合 luolili
 */
export function supplierList (params) {
    return http({
        url: "/api/fresh/supplier",
        data: params
    })
}
/**
 * 本社生鲜商品集合 luolili
 */
export function freshGoodsList (params) {
    return http({
        url: "/api/fresh/goods",
        data: params
    })
}


//endregion************************* 本社生鲜 end  ***************************************/

//region ************************* 湘赣红 start  ***************************************/
/**
 * 获取湘赣红 - 特色爆款商品 luolili
 */
export function getXghGoodsHot (params) {
    return http({
        url: "/api/Xgh/getXghGoodsHot",
        data: params
    })
}

/**
 * 获取湘赣红 - banner luolili
 */
export function getXghBanner (params) {
    return http({
        url: "/api/Xgh/getXghBanner",
        data: params
    })
}

/**
 * 获取湘赣红商品列表  luolili
 */
export function getXghGoodsLists (params) {
    return http({
        url: "/api/Xgh/getXghGoodsLists",
        data: params
    })
}

/**
 * 特色好物 luolili
 */
export function getXghGoodsGood (params) {
    return http({
        url: "/api/Xgh/getXghGoodsGood",
        data: params
    })
}

/**
 * 获取湘赣红 - 地区列表 luolili
 */
export function getXghAreaList (params) {
    return http({
        url: "/api/Xgh/getXghAreaList",
        data: params
    })
}

/**
 * 获取湘赣红 - 地区详情 luolili
 */
export function getXghAreaDetail (params) {
    return http({
        url: "/api/Xgh/getXghAreaDetail?id=" + params.id,
    })
}
//endregion /************************* 湘赣红 end  ***************************************/

//region************************* 重点帮扶产品 start  ***************************************/
/**
 * 特殊商品  chenyun
 * @param params
 * @returns {Promise|*}
 */
export function specailGoodsList (params) {
    return http({
        url: "/api/GoodsSpecial/goodslist",
        data: params
    })
}
/**
 * 重点帮扶地区  chenyun
 * @param params
 * @returns {Promise|*}
 */
export function getSpecailArea (params) {
    return http({
        url: "/api/GoodsSpecial/getArea",
        data: params
    })
}
//endregion************************* 重点帮扶产品 end  ***************************************/

//region************************* pcdiy专题活动 start  ***************************************/
export function getViewByPcdiy (params) {
    return http({
        url: "/api/pcDiy/getView",
        data: params
    })
}
//endregion************************* 重点帮扶产品 end  ***************************************/


