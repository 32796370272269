import http from "../utils/http"
import http2 from "../utils/http2"

/**
 * 楼层
 * @param {object} params
 */
export function floors(params) {
    return http({
        url: "/api/pc/floors",
        data: params
    })
}

/**
 * 获取热门搜索关键词
 */
export function apiHotSearchWords(params) {
    return http({
        url: "/api/pc/hotSearchWords",
        data: params
    })
}

/**
 * 获取默认搜索关键词
 */
export function apiDefaultSearchWords(params) {
    return http({
        url: "/api/pc/defaultSearchWords",
        data: params
    })
}
/**
 * 获取首页浮层
 */
export function floatLayer(params) {
    return http({
        url: "/api/pc/floatLayer",
        data: params
    })
}
/**
 * 获取首页排行榜数据
 */
export function rankingList(params) {
    return http({
        url: "/api/pc/ranking_list",
        data: params
    })
}

/**
 * 获取帮扶项目
 */
export function projectList(params) {
    return http2({
        url: "/api/project/lists",
        data: params
    })
}
/**
 * 获取帮扶项目详情
 */
export function projectDetail(params) {
    return http2({
        url: "/api/project/detail",
        data: params
    })
}
/**
 * 获取帮扶项目状态
 */
export function getProjectStatus (params) {
    return http2({
        url: "/api/project/getStatus",
        data: params
    })
}
/**
 * 获取帮扶项目类型
 */
export function getProjectType(params) {
    return http2({
        url: "/api/project/getProjectType",
        data: params
    })
}
/**
 * 获取帮扶项目行业
 */
export function getProjectIndustry(params) {
    return http2({
        url: "/api/projectIndustry/lists",
        data: params
    })
}
/**
 * 获取帮扶项目分类
 */
export function getProjectCategory(params) {
    return http2({
        url: "/api/projectCategory/lists",
        data: params
    })
}

/**
 * 获取第三方客服
 */
export function getCustomServiceUrl(params) {
    return http({
        url: "/api/customService/geturl",
        data: params
    })
}
