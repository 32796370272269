import http from "../utils/http"

/**
 * 店铺街2.0
 * 平台按城市调用，返回筛选城市下的门店，
 * @param {object} params
 */
export function shopPage2_0(params) {
    return http({
        url: "/api/shop/page2_0",
        data: params
    })
}
/**
 * 门店列表
 * 根据店铺id返回此店铺下的门店列表
 * @param {object} params
 */
export function storePage2_0(params) {
    return http({
        url: "/api/store/page2_0",
        data: params
    })
}

