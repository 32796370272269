<template>
    <el-container>
        <!-- 头部 -->
        <el-header height="auto" class="header"><RegionHeader /></el-header>
        <el-main class="content">
            <transition name="slide"><router-view /></transition
        ></el-main>
        <!-- 底部 -->
        <el-footer><newFooter /></el-footer>
    </el-container>
</template>
<script>
    import RegionHeader from "./components/RegionHeader.vue"
    import newFooter from "./components/newFooter"

    export default {
        created() {},
        data: () => {
            return {}
        },
        mounted() {},

        computed: {},

        watch: {},
        components: {
            RegionHeader,
            newFooter
        }
    }
</script>
<style lang="scss" scoped>
    .header {
        padding: 0;
    }
    .content {
        max-width: $width;
        margin: 0 auto;
        padding: 0;
    }
    .el-footer {
        padding: 0;
        height: auto !important;
        background-color: #fff;
    }

    .el-main {
        border-top: none;
    }
</style>
