import http from "../../utils/http"
/**
 *  帮扶单位 / 列表
 */
export function SupportLists(params) {
    return http({
        url: "/fupin/api/Support/lists",
        data: params,
        forceLogin: true
    })
}
/**
 *  帮扶单位 / 商品列表
 */
export function Supportgoodslists(params) {
    return http({
        url: "/fupin/api/Support/goodslists",
        data: params,
        forceLogin: true
    })
}
/**
 *  帮扶单位 / 商品分类
 */
export function Supporttre(params) {
    return http({
        url: "/fupin/api/Support/tree",
        data: params,
        forceLogin: true
    })
}
/**
 *  帮扶单位 / 专区详情
 */
export function Supportinfo(params) {
    return http({
        url: "/fupin/api/Support/info",
        data: params,
        forceLogin: true
    })
}
/**
 *  地方专区 / 专区列表
 */
export function Regionlists(params) {
    return http({
        url: "/fupin/api/Region/lists",
        data: params,
        forceLogin: true
    })
}
/**
 *  地方专区 / 商品分类
 */
export function Regiontree(params) {
    return http({
        url: "/fupin/api/Region/tree",
        data: params,
        forceLogin: true
    })
}
/**
 *  地方专区 / 专区详情
 */
export function Regioninfo(params) {
    return http({
        url: "/fupin/api/Region/info",
        data: params,
        forceLogin: true
    })
}
/**
 *  地方专区 / 商品列表
 */
export function Regiongoodslists(params) {
    return http({
        url: "/fupin/api/Region/goodslists",
        data: params,
        forceLogin: true
    })
}
/**
 *  地方专区 / 地区列表
 */
export function regionAreas(params) {
    return http({
        url: "/fupin/api/Region/regionAreas",
        data: params,
        forceLogin: true
    })
}
/**
 *  NEW 地方专区 / 获取市或者区
 */
export function haveGoodsRegion(params) {
    return http({
        url: "/fupin/api/Region/have_goods_region",
        data: params,
        forceLogin: true
    })
}
/**
 *  NEW 地方专区 / 获取某城市或者某区域下的商品的分类
 */
export function regionTree(params) {
    return http({
        url: "/fupin/api/Region/region_tree",
        data: params,
        forceLogin: true
    })
}
/**
 *  NEW 地方专区 / 获取某个市或者某个区下的商品
 */
export function regionGoodsLists(params) {
    return http({
        url: "/fupin/api/Region/region_goods_lists",
        data: params,
        forceLogin: true
    })
}
/**
 *  NEW 地方专区 / 获取某个市或者某个区下的商品
 */
export function city_or_district_id_name(params) {
    return http({
        url: "/fupin/api/Region/city_or_district_id_name",
        data: params,
        forceLogin: true
    })
}
