import LoginLayout from "../../layout/login"

// 登录模块
export default {
    path: "/auth",
    component: LoginLayout,
    redirect: "/auth/login",
    alwaysShow: true,
    name: "Auth",
    children: [
        {
            path: "/login",
            name: "login",
            meta: {
                module: "login",
                backgroundColor: "#fff",
				mainCss:{
					width: "100%"
				}
            },
            component: () => import("@/views/auth/login")
        },
        {
            path: "/register",
            name: "register",
            meta: {
                module: "login",
                backgroundColor: "#fff",
				mainCss:{
					width: "100%"
				}
            },
            component: () => import("@/views/auth/index")
        },
        {
            path: "/registerPersonal",
            name: "registerPersonal",
            meta: {
                module: "login",
                backgroundColor: "#fff",
				mainCss:{
					width: "100%"
				}
            },
            component: () => import("@/views/auth/register")
        },
        {
            path: "/registerCompany",
            name: "registerCompany",
            meta: {
                module: "login",
                backgroundColor: "#fff",
				mainCss:{
					width: "100%"
				}
            },
            component: () => import("@/views/auth/company")
        },
        {
            path: "/find_pass",
            name: "find_pass",
            meta: {
                module: "login",
                backgroundColor: "#fff",
				mainCss:{
					width: "100%"
				}
            },
            component: () => import("@/views/auth/find")
        },
		{
			path: "/licence",
			name: "licence",
			meta: {
				module: "licence",
				backgroundColor: "#fff",
				title: '信息网络营业许可证'
			},
			component: () => import("@/views/auth/licence")
		}
    ]
}
