import Vue from "vue"
import router from "./router"
import store from "./store"
import VueMeta from "vue-meta"
import Element from "element-ui"


import {
    Message
} from 'element-ui'

let messageInstance = null;
const overrideMessage = (options) => {
    if (messageInstance) {
        messageInstance.close()
    }
    messageInstance = Message(options)
}
['error', 'success', 'info', 'warning'].forEach(type => {
    overrideMessage[type] = options => {
        if (typeof options === 'string') {
            options = {
                message: options
            }
        }
        options.type = type
        return overrideMessage(options)
    }
})
Vue.use(Element)
Vue.prototype.$message = overrideMessage;
// 写入 cookies 的保存天数
Vue.prototype.$cookiesDays = 365;

import scroll from 'vue-seamless-scroll'

import "element-ui/lib/theme-chalk/index.css"
import "./assets/styles/main.scss"
import "./assets/styles/element-variables.scss"

import VideoPlayer from "vue-video-player"
import "video.js/dist/video-js.css"

import App from "./App.vue"

import Util from "./utils/util"
import Lang from "./utils/lang.js"
import Config from "./utils/config.js"
import { getwebsiteType } from "@/api/website.js"

Vue.prototype.$store = store //挂在vue
Vue.config.productionTip = false

// 常用工具函数
Vue.prototype.$util = Util
Vue.prototype.$img = Util.img
Vue.prototype.$timeStampTurnTime = Util.timeStampTurnTime
Vue.prototype.$copy = Util.copy
window.util = Util
window.img = Util.img
window.timeStampTurnTime = Util.timeStampTurnTime
window.copy = Util.copy

// 语言包
Vue.prototype.$langConfig = Lang //语言包对象
Vue.prototype.$lang = Lang.lang //解析语言包
window.langConfig = Lang
window.lang = Lang.lang

Vue.prototype.$config = Config

Vue.use(VideoPlayer)
Vue.use(VueMeta)
Vue.use(scroll)

window.vue = new Vue({
    router,
    store,
    metaInfo () {
        return {
            title: store.getters.siteInfo.title || "湖南省消费帮扶公共服务平台",
            meta: [
                { name: "title", content: store.getters.siteInfo.title || "湖南省消费帮扶公共服务平台" },
                { name: "desc", content: store.getters.siteInfo.desc || "" },
                { name: "keywords", content: store.getters.siteInfo.keywords || "" }
            ]
        }
    },
    render: h => h(App)
}).$mount("#app")



// 获取信息判断站点加载资源
// getwebsiteType(
//     {data: {website_type:0}}
// ).then((res) => {
//     console.log('...getwebsiteType...', res);
//     if (res.code == 0) {}
// }).catch(err => {
//     console.log('...getwebsiteType...catch...', err);})

