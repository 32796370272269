import ShopLayout from "../../layout/shop"
import supportLayout from "../../layout/support"

const shopRoutes = [
    {
        path: "/shop-*",
        name: "shop_index",
        meta: {
            module: "shop",
            backgroundColor: "#fff"
        },
        component: () => import("@/views/shop/index")
    },
    {
        path: "/shop_list",
        name: "shop_list",
        meta: {
            module: "shop"
        },
        component: () => import("@/views/shop/list")
    },
    {
        path: "/shop_street",
        name: "shop_street",
        meta: {
            module: "shop"
        },
        component: () => import("@/views/shop/streetin")
    }
]


export default {
        path: "/shop",
        component: ShopLayout,
        redirect: "/street",
        name: "shopIndex",
        children: [...shopRoutes]
    }
