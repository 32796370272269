<template>
    <div class="footer-bottom">
        <div class="site-info">
            指导单位：国务院扶贫办社会扶贫司、国务院扶贫办信息中心 京ICP备：{{ copyRight.icp }} 中国社会扶贫网 版权所有
        </div>
    </div>
</template>

<script>
    import { copyRight } from "@/api/website"
    import { mapGetters } from "vuex"
    export default {
        props: {},
        data() {
            return {}
        },
        created() {
            this.$store.dispatch("site/copyRight")
        },
        mounted() {},
        watch: {},
        methods: {},
        computed: {
            ...mapGetters(["copyRight", "siteInfo"])
        }
    }
</script>

<style scoped lang="scss">
    .footer-bottom {
        width: 100%;
      background-color: #05226b;
      color: #ffffff;
        margin: 0 auto;
        padding: 5px 0;
        p {
            margin: 0;
            width: 100%;
            box-sizing: border-box;
            text-align: center;
            img {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
            .footer-link, .el-link.el-link--default {
                color: #9d9d9d;
            }
			.footer-link:hover, .el-link.el-link--default:hover {
				color: $base-color;
			}
        }
        .site-info {
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                width: auto;
                margin: 0 10px;

                i {
                    vertical-align: bottom;
                    margin-right: 5px;
                }
            }
        }
    }
</style>
