<!-- 排行榜 充值排行 luoll-->
<template>
    <div class="ranking-list-wrap">
        <el-tabs  class="ranking-list-type" v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="各地采购排行" name="first" v-loading="loadingRank">
                <div class="ranking-body">
                    <el-row>
                        <div class="rankingFilter__items">
                            <div class="rankingFilter__items--label">地区</div>
                            <div class="rankingFilter__items--content">
                                <div class="city">
                                    <div class="items" :class="filter.area == '' ? 'active' : ''" @click="handleClearCity('area')">不限</div>
                                    <div class="items" :class="item.id == filter.area ? 'active' : ''" v-for="(item, index) in rankingList.area.address" :key="index" @click="handleSelectCity(item, 'area')">{{item.name}}</div>
                                </div>
                            </div>
                        </div>
                    </el-row>
                    <el-row :gutter="20" v-if="rankingList.area.list.length>0">
                        <el-col :span="10">
                            <div class="ranking-topthree" >
                                <img src="@/assets/images/index/ranking.png" alt="">
                                <div v-if="rankingList.area.list.length" >
                                    <div v-for="(item,index) in rankingList.area.list.slice(0,3)" :key="index">
                                        <div :class='["ranking-text",index==0 ? "NO1" : (index==1 ? "NO2":"NO3")]'>
                                            <p class="title">{{item.username?item.username:'匿名'}}</p>
                                            <p class="amount">{{item.money}}元</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="2"><div><br/></div></el-col>
                        <el-col :span="12">
                            <div class="ranking_table">
                                <el-row class="ranking_table_head">
                                    <el-col :span="3">排名</el-col>
                                    <el-col class="textCenter" :span="14">采购区域</el-col>
                                    <el-col class="textCenter" :span="7">销售金额（元）</el-col>
                                </el-row>
                                <div class="ranking_table_box" v-if="rankingList.area.list.length > 3">
                                    <el-row class="ranking_table_body" v-for="(item,index) of rankingList.area.list.slice(3,rankingList.area.list.length)" :key="index">
                                        <el-col :span="3">
                                            <div class="num">{{index + 4}}</div>
                                        </el-col>
                                        <el-col class="textCenter" :span="14">
                                            <el-tooltip class="item" effect="dark" :content="item.username?item.username:'匿名'" placement="top">
                                                <p class="ellipsis">{{item.username?item.username:'匿名'}}</p>
                                            </el-tooltip>
                                        </el-col>
                                        <el-col class="textCenter" :span="7">{{item.money}}</el-col>
                                    </el-row>
                                </div>
                                <div class="ranking_table_box" v-else>暂无数据</div>
                                <el-row class="ranking_bottom">
                                    <el-col :span="12" class="ranking_bottom_text">{{rankingFilter.area.level==1?'市/州':'区/县'}}总数：{{rankingList.area.number}}个</el-col>
                                    <el-col :span="12" class="ranking_bottom_text">销售总额：{{rankingList.area.total}}元</el-col>
                                </el-row>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="ranking_empty" v-else>暂无数据</div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="各单位采购排行" name="second" v-loading="loadingRank">
                <div class="ranking-body">
                    <el-row>
                        <div class="rankingFilter__items">
                            <div class="rankingFilter__items--label">类别</div>
                            <div class="rankingFilter__items--content">
                                <div class="city">
                                    <div class="items" :class="!filter.company ? 'active' : ''" @click="handleClearCity('company')">不限</div>
                                    <div class="items" :class="item.type == filter.company ? 'active' : ''" v-for="(item, index) in rankingList.company.unit.list" :key="index" @click="handleSelectCity(item.type, 'company')">{{item.name}}</div>
                                </div>
                            </div>
                        </div>
                    </el-row>
                    <el-row :gutter="20" v-if="rankingList.company.list.length>0">
                        <el-col :span="12">
                            <div class="ranking_table">
                                <el-row class="ranking_table_head">
                                    <el-col :span="3">排名</el-col>
                                    <el-col class="textCenter" :span="14">采购单位</el-col>
                                    <el-col class="textCenter" :span="7">销售金额（元）</el-col>
                                </el-row>
                                <div class="ranking_table_box"  v-if="rankingList.company.list.length > 3">
                                    <el-row class="ranking_table_body" v-for="(item,index) of rankingList.company.list.slice(3,rankingList.company.list.length)" :key="index">
                                        <el-col :span="3">
                                            <div class="num">{{index + 4}}</div>
                                        </el-col>
                                        <el-col class="textCenter" :span="14">
                                            <el-tooltip class="item" effect="dark" :content="item.org_name?item.org_name:'匿名'" placement="top">
                                                <p class="ellipsis">{{item.org_name?item.org_name:'匿名'}}</p>
                                            </el-tooltip>
                                        </el-col>
                                        <el-col class="textCenter" :span="7">{{item.money}}</el-col>
                                    </el-row>
                                </div>
                                <div class="ranking_table_box" v-else>暂无数据</div>
                                <el-row class="ranking_bottom">
                                    <el-col :span="12" class="ranking_bottom_text">单位总数：{{rankingList.company.number}}个</el-col>
                                    <el-col :span="12" class="ranking_bottom_text">采购总额：{{rankingList.company.total}}元</el-col>
                                </el-row>
                            </div>
                        </el-col>
                        <el-col :span="2"><div><br/></div></el-col>
                        <el-col :span="10">
                            <div class="ranking-topthree">
                                <img src="@/assets/images/index/ranking.png" alt="">
                                <div v-if="rankingList.company.list.length" >
                                    <div v-for="(item,index) in rankingList.company.list.slice(0,3)" :key="index">
                                        <div :class='["ranking-text",index==0 ? "NO1" : (index==1 ? "NO2":"NO3")]'>
                                            <p class="title">{{item.org_name?item.org_name:'匿名'}}</p>
                                            <p class="amount">{{item.money}}元</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="ranking_empty" v-else>暂无数据</div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="单位充值排行" name="third" v-loading="loadingRank">
                <div class="ranking-body">
                    <el-row>
                        <div class="rankingFilter__items">
                            <div class="rankingFilter__items--label">类别</div>
                            <div class="rankingFilter__items--content">
                                <div class="city">
                                    <div class="items" :class="!filter.voucher ? 'active' : ''" @click="handleClearCity('voucher')">不限</div>
                                    <div class="items" :class="item.type == filter.voucher ? 'active' : ''" v-for="(item, index) in rankingList.voucher.unit.list" :key="index" @click="handleSelectCity(item.type, 'voucher')">{{item.name}}</div>
                                </div>
                            </div>
                        </div>
                    </el-row>
                    <el-row :gutter="20" v-if="rankingList.voucher.list.length>0">
                        <el-col :span="10">
                            <div class="ranking-topthree">
                                <img src="@/assets/images/index/ranking.png" alt="">
                                <div v-if="rankingList.voucher.list.length" >
                                    <div v-for="(item,index) in rankingList.voucher.list.slice(0,3)" :key="index">
                                        <div :class='["ranking-text",index==0 ? "NO1" : (index==1 ? "NO2":"NO3")]'>
                                            <p class="title">{{(typeof item.org_name != 'undefind' || item.org_name != '') ? item.org_name :'匿名'}}</p>
                                            <p class="amount">{{item.money}}元</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="2"><div><br/></div></el-col>
                        <el-col :span="12">
                            <div class="ranking_table">
                                <el-row class="ranking_table_head">
                                    <el-col :span="4">排名</el-col>
                                    <el-col class="textCenter" :span="12">充值单位</el-col>
                                    <el-col class="textCenter" :span="8">充值金额(元)</el-col>
                                </el-row>
                                <div class="ranking_table_box" v-if="rankingList.voucher.list.length > 3">
                                    <el-row class="ranking_table_body" v-for="(item,index) of rankingList.voucher.list.slice(3,rankingList.voucher.list.length)" :key="index">
                                        <el-col :span="4">
                                            <div class="num">{{index + 4}}</div>
                                        </el-col>
                                        <el-col class="textCenter" :span="12">
                                            <el-tooltip class="item" effect="dark" :content="item.org_name?item.org_name:'匿名'" placement="top">
                                                <p class="ellipsis">{{item.org_name?item.org_name:'匿名'}}</p>
                                            </el-tooltip>
                                        </el-col>
                                        <el-col class="textCenter" :span="8">{{item.money}}</el-col>
                                    </el-row>
                                </div>
                                <div class="ranking_table_box" v-else>暂无数据</div>
                                <el-row class="ranking_bottom">
                                    <el-col :span="12" class="ranking_bottom_text">单位总数：{{rankingList.voucher.number}}个</el-col>
                                    <el-col :span="12" class="ranking_bottom_text">充值总额：{{rankingList.voucher.total}}元</el-col>
                                </el-row>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="ranking_empty" v-else>暂无数据</div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="商家销售排行" name="shop" v-loading="loadingRank">
                <div class="ranking-body">
                    <el-row>
                        <div class="rankingFilter__items">
                            <div class="rankingFilter__items--label">地区</div>
                            <div class="rankingFilter__items--content">
                                <div class="city">
                                    <div class="items" :class="filter.shop == '' ? 'active' : ''" @click="handleClearCity('shop')">不限</div>
                                    <div class="items" :class="item.id == filter.shop ? 'active' : ''" v-for="(item, index) in rankingList.shop.address" :key="index" @click="handleSelectCity(item, 'shop')">{{item.name}}</div>
                                </div>
                            </div>
                        </div>
                    </el-row>
                    <el-row :gutter="20" v-if="rankingList.shop.list.length>0">
                        <el-col :span="10">
                            <div class="ranking-topthree" >
                                <img src="@/assets/images/index/ranking.png" alt="">
                                <div v-if="rankingList.shop.list.length" >
                                    <div v-for="(item,index) in rankingList.shop.list.slice(0,3)" :key="index">
                                        <div :class='["ranking-text",index==0 ? "NO1" : (index==1 ? "NO2":"NO3")]'>
                                            <a style="cursor: pointer" @click="$router.pushToTab('shop-' + item.site_id)"><p class="title">{{item.username?item.username:'匿名'}}</p></a>
                                            <p class="amount">{{item.money}}元</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="2"><div><br/></div></el-col>
                        <el-col :span="12">
                            <div class="ranking_table">
                                <el-row class="ranking_table_head">
                                    <el-col :span="3">排名</el-col>
                                    <el-col class="textCenter" :span="14">商家名称</el-col>
                                    <el-col class="textCenter" :span="7">销售金额（元）</el-col>
                                </el-row>
                                <div class="ranking_table_box" v-if="rankingList.shop.list.length > 3">
                                    <el-row class="ranking_table_body" v-for="(item,index) of rankingList.shop.list.slice(3,rankingList.shop.list.length)" :key="index">
                                        <el-col :span="3">
                                            <div class="num">{{index + 4}}</div>
                                        </el-col>
                                        <el-col class="textCenter" :span="14">
                                            <el-tooltip class="item" effect="dark" :content="item.username?item.username:'匿名'" placement="top">
                                                <p class="ellipsis"><a style="cursor: pointer" @click="$router.pushToTab('shop-' + item.site_id)">{{item.username?item.username:'匿名'}}</a></p>
                                            </el-tooltip>
                                        </el-col>
                                        <el-col class="textCenter" :span="7">{{item.money}}</el-col>
                                    </el-row>
                                </div>
                                <div class="ranking_table_box" v-else>暂无数据</div>
                                <el-row class="ranking_bottom">
                                    <el-col :span="12" class="ranking_bottom_text">商家总数：{{rankingList.shop.number}}家</el-col>
                                    <el-col :span="12" class="ranking_bottom_text">销售总额：{{rankingList.shop.total}}元</el-col>
                                </el-row>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="ranking_empty" v-else>暂无数据</div>
                </div>
            </el-tab-pane>
        </el-tabs>
        <div  class="real-time-trading" v-if="rankingList.order_real_time.length>0">
            <div class="real-time-label">实时交易：</div>
            <ul class="list">
                <li v-for="(item, index) in rankingList.order_real_time_one"
                    :key="item.id"
                    :class="(!(index) && play) ? 'toUp' : '' ">
                    <span class="red">{{item.name}}</span>
                    <span>于</span>
                    <span class="red">{{$util.timeStampTurnTime(item.create_time)}}</span>
                    <span>消费金额</span>
                    <span class="red">{{item.money}}</span>
                    <span>元！</span>
                </li>
            </ul>
            <ul class="list">
                <li v-for="(item, index) in rankingList.order_real_time_two"
                    :key="item.id"
                    :class="(!(index) && play) ? 'toUp' : '' ">
                    <span class="red">{{item.name}}</span>
                    <span>于</span>
                    <span class="red">{{$util.timeStampTurnTime(item.create_time)}}</span>
                    <span>消费金额</span>
                    <span class="red">{{item.money}}</span>
                    <span>元！</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import {rankingList} from "@/api/pc";

    export default {
        name: "ranking-style-1.vue",
        components: {},
        computed: {
        },
        data() {
            return {
                loadingRank : true,
                activeName: 'first',
                rankingList: {
                    area:{
                        address:[],
                        list:[],
                        number:0,
                        total:0,
                    },
                    company:{
                        unit:[],
                        list:[],
                        number:0,
                        total:0,
                    },
                    voucher:{
                        unit:[],
                        list:[],
                        number:0,
                        total:0,
                    },
                    shop:{
                        address:[],
                        list:[],
                        number:0,
                        total:0,
                    },
                    order_real_time: [],
                    order_real_time_one: [],
                    order_real_time_two: [],
                    voucher_real_time: [],
                },
                filter: {
                    area: '',
                    company: '',
                    voucher: '',
                    shop: '',
                },
                rankingFilter:{
                    area:{type:'area',area_id:'',level:1},
                    company:{type:'company',org_type:''},
                    voucher:{type:'voucher',org_type:''},
                    shop:{type:'shop',area_id:'',level:1},
                },
                play: false,
            };
        },
        mounted() {
            this.getRankingList('area',this.rankingFilter.area) //地区

            this.getRankingList('order_real_time',{type:'order_real_time'}) //实时订单
            setInterval(this.startPlay, 2000)

        },
        methods: {
            startPlay() {
                let that = this
                that.play = true //开始播放
                setTimeout(() => {
                    this.rankingList.order_real_time_one.push(this.rankingList.order_real_time_one[0]) //将第一条数据塞到最后一个
                    this.rankingList.order_real_time_one.shift() //删除第一条数据

                    this.rankingList.order_real_time_two.push(this.rankingList.order_real_time_two[0]) //将第一条数据塞到最后一个
                    this.rankingList.order_real_time_two.shift() //删除第一条数据
                    that.play = false //暂停播放
                }, 500)
            },
            /*排行榜数据*/
            getRankingList(type,paramObj){
                this.loadingRank = true;
                rankingList(paramObj)
                    .then(res => {
                        // console.log('排行榜数据==',res.data)
                        if(type == 'area'){
                            if(this.rankingFilter.area.level>1)//只到市就可以了
                                res.data.address        = this.rankingList.area.address
                            this.rankingList.area   = res.data
                        }else if(type == 'company'){
                            this.rankingList.company = res.data
                        }else if(type == 'voucher'){
                            this.rankingList.voucher = res.data
                        }else if(type == 'order_real_time'){
                            this.rankingList.order_real_time = res.data.list
                            // console.log('0==',res.data.list);
                            let one = [],two = [];
                            res.data.list.filter(function (item,index) {
                                if((index+1)%2 == 0){
                                    return two.push(item)
                                }else{
                                    return one.push(item)
                                }
                            });
                            this.rankingList.order_real_time_two =  two
                            this.rankingList.order_real_time_one =  one
                        }else if(type == 'shop'){
                            if(this.rankingFilter.shop.level>1)//只到市就可以了
                                res.data.address        = this.rankingList.shop.address
                            this.rankingList.shop   = res.data
                        }
                        this.loadingRank = false;
                    })
                    .catch(err => {
                        this.loadingRank = false;
                        console.log(err)
                    })
            },
            handleSelectCity(item, type) {
                if(type === 'area'){
                    if(item.level>2) return false;//最多只让查询市区的
                    this.rankingFilter.area.area_id = this.filter.area = item.id
                    this.rankingFilter.area.level = item.level
                }else if(type === 'company'){
                    this.rankingFilter.company.org_type = this.filter.company = item
                }else if(type === 'voucher'){
                    this.rankingFilter.voucher.org_type = this.filter.voucher = item
                }else if(type === 'shop'){
                    if(item.level>2) return false;//最多只让查询市区的
                    this.rankingFilter.shop.area_id = this.filter.shop = item.id
                    this.rankingFilter.shop.level = item.level
                }
                this.getRankingList(type, this.rankingFilter[type])
            },
            handleClearCity(type) {
                if(type === 'area'){
                    this.rankingFilter.area.area_id = this.filter.area = ''
                    this.rankingFilter.area.level = 1
                }else if(type === 'company'){
                    this.rankingFilter.company.org_type = this.filter.company = ''
                }else if(type === 'voucher'){
                    this.rankingFilter.voucher.org_type = this.filter.voucher = ''
                }else if(type === 'shop'){
                    this.rankingFilter.shop.area_id = this.filter.area = ''
                    this.rankingFilter.shop.level = 1
                }
                this.getRankingList(type, this.rankingFilter[type])
            },
            handleClick(tab, event) {
                if(tab.name == 'first'){
                    this.getRankingList('area',this.rankingFilter.area) //地区
                }else if(tab.name == 'second'){
                    this.getRankingList('company',this.rankingFilter.company) //单位
                }else if(tab.name == 'third'){
                    this.getRankingList('voucher',this.rankingFilter.voucher) //充值
                }else if(tab.name == 'shop'){
                    this.getRankingList('shop',this.rankingFilter.shop) //商家排行
                }
            }
        }
    }
</script>

<style  lang="scss">
    /*排行榜*/
    .toUp {
        margin-top: -40px;
        transition: all 0.5s;
    }
    .ranking-list-wrap .real-time-trading{
        height: 40px;
        border-top:1px solid #f1f1ff;
        width: 1210px;
        display: block;
        margin:0 auto;
        background-color:#fff;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;

        .real-time-label{
            width: 200px;
            margin-right:0px;
            float: left;
            text-align: center;
            color:#301D1D;
            font-weight: 600;
            font-size: 13px;
            line-height: 40px;
        }
        .list {
            float: left;
            list-style: none;
            width: 505px;
            overflow: hidden;
            height: 40px;
            padding: 0;
            margin: 0;
        }
        li {
            text-align: left;
            height: 40px;
            line-height: 40px;
            font-size: 13px;
            /*width: 45%;*/
            /*float: left;*/
            span{
                padding:0px 5px;
                color:$base-color;
            }
            span.red{
                color:$base-color;
            }
        }
    }

    .ranking-list-type{
        width: 1210px;
        display: block;
        margin:20px auto 0px;
        background-color:#fff;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
    }
    .ranking-list-type.el-tabs--card > .el-tabs__header .el-tabs__nav{
        width: 100%;
        border:none;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        display: flex;
    }
    .ranking-list-type.el-tabs--card > .el-tabs__header .el-tabs__item{
        width: 33.33%;
        border:none;
        height: 43px;
        background-color: $base-color;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        line-height: 42px;
        border-left:1px solid #fff;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
    }
    .ranking-list-type.el-tabs--card > .el-tabs__header .el-tabs__item.is-active{
        background-color: #fff;
        color: $base-color;
    }
    .ranking-list-wrap .ranking-body{padding:0px 15px 20px;}
    .ranking_table{
        position:relative;
        height:270px;
        .ranking_table_head{
            background-color: #d3cfcf;
            height: 45px;
            color: #301D1D;
            line-height: 45px;
            text-align: center;
            font-size: 15px;
            font-weight: 600;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
        }
        .ranking_table_box{
            height:200px;
            overflow:hidden;
            position: relative;
            text-align: center;
            border: 1px solid #d3cfcf;
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
            line-height: 200px;
            font-size: 15px;

            .ranking_table_body{
                height:40px;
                line-height: 40px;
                font-size: 14px;
                .num{
                    background-color: #837777;
                    width: 25px;
                    line-height: 25px;
                    height: 25px;
                    text-align: center;
                    border-radius: 7px;
                    color: #fff;
                    margin: 7px auto 0;
                }
                .ellipsis {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

            }
            .ranking_table_body:nth-child(even){
                background-color: #f7f7f7;
            }
        }
    }
    .ranking_empty{
        height:200px;
        overflow:hidden;
        position: relative;
        text-align: center;
        line-height: 200px;
        font-size: 15px;
    }
    .ranking-list-type .ranking_bottom{
        position:absolute;
        width:100%;
        height:30px;
        right:0px;
        bottom:-10px;
        line-height:30px;
        font-size:16px;
        color:#301D1D;
    }
    .ranking-list-type .rankingFilter__items{
        background: #fff;
        margin: 0 0 28px 0;
        padding: 0px 10px;
        height: 18px;
        position: relative;
    }
    .ranking-list-type .rankingFilter__items--label{
        float: left;
        margin-right: 10px;
        margin-left: 10px;
        color: #666;
        width: 40px;
    }
    .ranking-list-type .rankingFilter__items--content {
        float: left;
        width: 1100px;
        margin-bottom: 20px;
    }
    .ranking-list-type .rankingFilter__items--content .items {
        display: inline-block;
        padding: 0 10px;
        margin-bottom: 5px;
        cursor: pointer;
        color:#999;
    }
    .ranking-list-type .rankingFilter__items--content .items:hover,
    .ranking-list-type .rankingFilter__items--content .items.active {
        color: $base-color;
    }
    .ranking-topthree{
        width: 100%;
        margin: 0 auto;
        position: relative;
        display: block;
        border-radius: 10px;
        .ranking-text{
            width: 120px;
            height:50px;
            position:absolute;
            text-align: center;
            .title{
                min-height:20px;
                font-size: 13px;
                line-height: 22px;
                font-weight: 600;
                color: #301D1D;
            }
            .amount{
                color: $base-color;
                font-size: 16px;
                line-height: 30px;
            }
        }
        .ranking-text.NO1{
            left:185px;
            top:15px
        }
        .ranking-text.NO2{
            left:45px;
            top:60px
        }
        .ranking-text.NO3{
            left:320px;
            top:80px
        }
        img{
            margin-top: 80px;
        }
    }
    .ranking_bottom_text{
        font-size: 16px;
        color:#666;
    }
    /*排行榜end*/
</style>