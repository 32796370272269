<!-- 排行榜 商家排行 luoll-->
<template>
    <div>
        <div class="real-time-trading" v-if="rankingList.order_real_time.length>0">
            <div class="real-time-label"><img src="@/assets/images/nationwide/trumpet.png" alt="" style="margin-right: 10px;">实时交易：</div>
            <ul class="list">
                <li v-for="(item, index) in rankingList.order_real_time_one"
                    :key="item.id"
                    :class="(!(index) && play) ? 'toUp' : '' ">
                    <span class="red">{{item.name}}</span>
                    <span>于</span>
                    <span class="red">{{$util.timeStampTurnTime(item.create_time)}}</span>
                    <span>消费金额</span>
                    <span class="red">{{item.money}}</span>
                    <span>元！</span>
                </li>
            </ul>
            <ul class="list">
                <li v-for="(item, index) in rankingList.order_real_time_two"
                    :key="item.id"
                    :class="(!(index) && play) ? 'toUp' : '' ">
                    <span class="red">{{item.name}}</span>
                    <span>于</span>
                    <span class="red">{{$util.timeStampTurnTime(item.create_time)}}</span>
                    <span>消费金额</span>
                    <span class="red">{{item.money}}</span>
                    <span>元！</span>
                </li>
            </ul>
        </div>
        <div class="ranking-wrap">
            <div class="floor-head" >
                <h2>
                    <img src="@/assets/images/nationwide/title_l.png"/>
                    排行榜
                    <img src="@/assets/images/nationwide/title_r.png"/>
                </h2>
            </div>
            <div class="ranking-content">
                <div class="c-left">
                    <div class="ranking-header">
                        <p>累计本平台采购金额</p>
                        <p><b class="price">¥{{purchaseAmount}}</b>万元</p>
                    </div>
                    <div>
                        <p class="title">各地采购排行榜</p>
                        <div class="ranking_table">
                            <el-row class="ranking_table_head">
                                <el-col :span="5" style="text-align: left">排名</el-col>
                                <el-col class="textLeft" :span="12" style="text-align: left">采购区域</el-col>
                                <el-col class="textCenter" :span="7"  style="text-align: right">销售金额(元)</el-col>
                            </el-row>
                            <div class="ranking_table_box" v-if="rankingList.area.list.length" style="height: 200px">
                                <el-row class="ranking_table_body" v-for="(item,index) of rankingList.area.list" :key="index">
                                    <el-col :span="5" style="text-align: left">
                                        <div class="num" v-if="index>2">{{index+1}}</div>
                                        <div class="numIcon" v-else>
                                            <img v-show="index == 0" src="@/assets/images/nationwide/num1.png"/>
                                            <img v-show="index == 1" src="@/assets/images/nationwide/num2.png"/>
                                            <img v-show="index == 2" src="@/assets/images/nationwide/num3.png"/>
                                        </div>
                                    </el-col>
                                    <el-col class="textLeft" :span="12" style="text-align: left">
                                        <p class="ellipsis">{{item.username?item.username:'匿名'}}</p>
                                    </el-col>
                                    <el-col class="textCenter" :span="7" style="text-align: right">{{item.money}}</el-col>
                                </el-row>
                            </div>
                            <div class="ranking_table_box" v-else style="height: 200px">暂无数据</div>
                            <div class="more" style="padding-right:10px;display: none">查看更多<img src="@/assets/images/nationwide/more.png"/></div>
                        </div>
                    </div>
                </div>
                <div class="c-right">
                    <el-tabs  class="ranking-list" v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="各单位采购排行" name="first" v-loading="loadingRank">
                            <div class="ranking-body">
                                <el-row>
                                    <div class="ranking_table">
                                        <el-row class="ranking_table_head">
                                            <el-col :span="3" style="text-align: left">排名</el-col>
                                            <el-col class="textLeft" :span="14" style="text-align: left">采购单位</el-col>
                                            <el-col class="textCenter" :span="7" style="text-align: right">销售金额(元)</el-col>
                                        </el-row>
                                        <div class="ranking_table_box"  v-if="rankingList.company.list.length">
                                            <el-row class="ranking_table_body" v-for="(item,index) of rankingList.company.list" :key="index">
                                                <el-col :span="3" style="text-align: left">
                                                    <div class="num" v-if="index>2">{{index+1}}</div>
                                                    <div class="numIcon" v-else>
                                                        <img v-show="index == 0" src="@/assets/images/nationwide/num1.png"/>
                                                        <img v-show="index == 1" src="@/assets/images/nationwide/num2.png"/>
                                                        <img v-show="index == 2" src="@/assets/images/nationwide/num3.png"/>
                                                    </div>
                                                </el-col>
                                                <el-col class="textLeft" :span="14" style="text-align: left">
                                                    <p class="ellipsis">{{item.org_name?item.org_name:'匿名'}}</p>
                                                </el-col>
                                                <el-col class="textCenter" :span="7" style="text-align: right">{{item.money}}</el-col>
                                            </el-row>
                                        </div>
                                        <div class="ranking_table_box" v-else>暂无数据</div>
                                        <div class="more" style="padding-right:10px;display:none">查看更多<img src="@/assets/images/nationwide/more.png"/></div>
                                    </div>
                                </el-row>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="单位充值排行" name="third" v-loading="loadingRank">
                            <div class="ranking-body">
                                <el-row>
                                    <div class="ranking_table">
                                        <el-row class="ranking_table_head">
                                            <el-col :span="3" style="text-align: left">排名</el-col>
                                            <el-col class="textLeft" :span="14" style="text-align: left">充值单位</el-col>
                                            <el-col class="textCenter" :span="7" style="text-align: right">充值金额(元)</el-col>
                                        </el-row>
                                        <div class="ranking_table_box" v-if="rankingList.voucher.list.length">
                                            <el-row class="ranking_table_body" v-for="(item,index) of rankingList.voucher.list" :key="index">
                                                <el-col :span="3" style="text-align: left">
                                                    <div class="num" v-if="index>2">{{index+1}}</div>
                                                    <div class="numIcon" v-else>
                                                        <img v-show="index == 0" src="@/assets/images/nationwide/num1.png"/>
                                                        <img v-show="index == 1" src="@/assets/images/nationwide/num2.png"/>
                                                        <img v-show="index == 2" src="@/assets/images/nationwide/num3.png"/>
                                                    </div>
                                                </el-col>
                                                <el-col class="textLeft" :span="14" style="text-align: left">
                                                    <p class="ellipsis">{{item.org_name?item.org_name:'匿名'}}</p>
                                                </el-col>
                                                <el-col class="textCenter" :span="7" style="text-align: right">{{item.money}}</el-col>
                                            </el-row>
                                        </div>
                                        <div class="ranking_table_box" v-else>暂无数据</div>
                                        <div class="more" style="padding-right:10px;display:none">查看更多<img src="@/assets/images/nationwide/more.png"/></div>
                                    </div>
                                </el-row>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="商家销售排行" name="shop" v-loading="loadingRank">
                            <div class="ranking-body">
                                <el-row>
                                    <div class="ranking_table">
                                        <el-row class="ranking_table_head">
                                            <el-col :span="3" style="text-align: left">排名</el-col>
                                            <el-col class="textLeft" :span="14" style="text-align: left">商家名称</el-col>
                                            <el-col class="textCenter" :span="7" style="text-align: right">销售金额(元)</el-col>
                                        </el-row>
                                        <div class="ranking_table_box" v-if="rankingList.shop.list.length ">
                                            <el-row class="ranking_table_body" v-for="(item,index) of rankingList.shop.list" :key="index">
                                                <el-col :span="3" style="text-align: left">
                                                    <div class="num" v-if="index>2">{{index+1}}</div>
                                                    <div class="numIcon" v-else>
                                                        <img v-show="index == 0" src="@/assets/images/nationwide/num1.png"/>
                                                        <img v-show="index == 1" src="@/assets/images/nationwide/num2.png"/>
                                                        <img v-show="index == 2" src="@/assets/images/nationwide/num3.png"/>
                                                    </div>
                                                </el-col>
                                                <el-col class="textLeft" :span="14" style="text-align: left">
                                                    <!--                                                    <el-tooltip class="item" effect="dark" :content="item.username?item.username:'匿名'" placement="top">-->
                                                    <!--                                                        <p class="ellipsis"><a style="cursor: pointer" @click="$router.pushToTab('shop-' + item.site_id)">{{item.username?item.username:'匿名'}}</a></p>-->
                                                    <!--                                                    </el-tooltip>-->
                                                    <p class="ellipsis"><a style="cursor: pointer" @click="$router.pushToTab('shop-' + item.site_id)">{{item.username?item.username:'匿名'}}</a></p>
                                                </el-col>
                                                <el-col class="textCenter" :span="7" style="text-align: right">{{item.money}}</el-col>
                                            </el-row>
                                        </div>
                                        <div class="ranking_table_box" v-else>暂无数据</div>
                                        <div class="more" style="padding-right:10px;display:none">查看更多<img src="@/assets/images/nationwide/more.png"/></div>
                                    </div>
                                </el-row>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <div class="c-line"></div>
                <div class="clear"></div>
            </div>
            <div class="c-start"></div>
        </div>
    </div>
</template>

<script>
    import {rankingList} from "@/api/pc";

    export default {
        name: "ranking-style-1.vue",
        components: {},
        computed: {},
        data() {
            return {
                loadingRank : true,
                activeName: 'first',
                purchaseAmount: 0,
                rankingList: {
                    area:{
                        address:[],
                        list:[],
                        number:0,
                        total:0,
                    },
                    company:{
                        unit:[],
                        list:[],
                        number:0,
                        total:0,
                    },
                    voucher:{
                        unit:[],
                        list:[],
                        number:0,
                        total:0,
                    },
                    shop:{
                        address:[],
                        list:[],
                        number:0,
                        total:0,
                    },
                    order_real_time: [],
                    order_real_time_one: [],
                    order_real_time_two: [],
                    voucher_real_time: [],
                },
                filter: {
                    area: '0',
                    company: '',
                    voucher: '',
                    shop: '',
                },
                rankingFilter:{
                    area:{type:'area',area_id:'0',level:0},
                    company:{type:'company',org_type:''},
                    voucher:{type:'voucher',org_type:''},
                    shop:{type:'shop',area_id:'0',level:0},
                },
                play: false,
            };
        },
        mounted() {
            this.getRankingList('whole',this.rankingFilter.area) //地区
            this.getRankingList('company',this.rankingFilter.company) //单位

            this.getRankingList('order_real_time',{type:'order_real_time'}) //实时订单
            setInterval(this.startPlay, 2000)

        },
        methods: {
            startPlay() {
                let that = this
                that.play = true //开始播放
                setTimeout(() => {
                    this.rankingList.order_real_time_one.push(this.rankingList.order_real_time_one[0]) //将第一条数据塞到最后一个
                    this.rankingList.order_real_time_one.shift() //删除第一条数据

                    this.rankingList.order_real_time_two.push(this.rankingList.order_real_time_two[0]) //将第一条数据塞到最后一个
                    this.rankingList.order_real_time_two.shift() //删除第一条数据

                    that.play = false //暂停播放
                }, 500)
            },
            /*排行榜数据*/
            getRankingList(type,paramObj){
                this.loadingRank = true;
                rankingList(paramObj)
                    .then(res => {
                        // console.log('排行榜数据==',res.data)
                        if(type == 'whole'){
                            if(this.rankingFilter.area.level>1) {//只到市就可以了
                                res.data.address = this.rankingList.area.address
                            }
                            this.rankingList.area = res.data
                            this.purchaseAmount = parseFloat(parseInt(res.data.total)/10000).toFixed(2)
                        }else if(type == 'company'){
                            this.rankingList.company = res.data
                        }else if(type == 'voucher'){
                            this.rankingList.voucher = res.data
                        }else if(type == 'order_real_time'){
                            this.rankingList.order_real_time = res.data.list
                            // console.log('0==',res.data.list);
                            let one = [],two = [];
                            res.data.list.filter(function (item,index) {
                                if((index+1)%2 == 0){
                                    return two.push(item)
                                }else{
                                    return one.push(item)
                                }
                            });
                            this.rankingList.order_real_time_two =  two
                            this.rankingList.order_real_time_one =  one

                        }else if(type == 'shop'){
                            if(this.rankingFilter.shop.level>1)//只到市就可以了
                                res.data.address = this.rankingList.shop.address
                            this.rankingList.shop   = res.data
                        }
                        this.loadingRank = false;
                    })
                    .catch(err => {
                        this.loadingRank = false;
                        console.log(err)
                    })
            },
            handleSelectCity(item, type) {
                if(type === 'whole'){
                    if(item.level>2) return false;//最多只让查询市区的
                    this.rankingFilter.area.area_id = this.filter.area = item.id
                    this.rankingFilter.area.level = item.level
                }else if(type === 'company'){
                    this.rankingFilter.company.org_type = this.filter.company = item
                }else if(type === 'voucher'){
                    this.rankingFilter.voucher.org_type = this.filter.voucher = item
                }else if(type === 'shop'){
                    if(item.level>2) return false;//最多只让查询市区的
                    this.rankingFilter.shop.area_id = this.filter.shop = item.id
                    this.rankingFilter.shop.level = item.level
                }
                this.getRankingList(type, this.rankingFilter[type])
            },
            handleClearCity(type) {
                if(type === 'whole'){
                    this.rankingFilter.area.area_id = this.filter.area = ''
                    this.rankingFilter.area.level = 1
                }else if(type === 'company'){
                    this.rankingFilter.company.org_type = this.filter.company = ''
                }else if(type === 'voucher'){
                    this.rankingFilter.voucher.org_type = this.filter.voucher = ''
                }else if(type === 'shop'){
                    this.rankingFilter.shop.area_id = this.filter.area = ''
                    this.rankingFilter.shop.level = 1
                }
                this.getRankingList(type, this.rankingFilter[type])
            },
            handleClick(tab, event) {
                if(tab.name == 'first'){
                    this.getRankingList('company',this.rankingFilter.company) //单位
                }else if(tab.name == 'third'){
                    this.getRankingList('voucher',this.rankingFilter.voucher) //充值
                }else if(tab.name == 'shop'){
                    this.getRankingList('shop',this.rankingFilter.shop) //商家排行
                }
            }
        }
    }
</script>

<style  lang="scss">
    /*排行榜*/
    .toUp {
        margin-top: -46px;
        transition: all 0.5s;
    }
    .real-time-trading{
        height: 66px;
        width: 1210px;
        display: block;
        margin:20px auto 0px;
        background-color:#fff;
        border-radius: 8px;

        .real-time-label{
            width: 200px;
            margin-right:0px;
            float: left;
            text-align: center;
            color:$ns-text-color-black;
            font-weight: 600;
            font-size: 16px;
            line-height: 66px;
        }
        .list {
            float: left;
            list-style: none;
            width: 505px;
            overflow: hidden;
            height: 46px;
            padding: 0;
            margin: 10px 0px;
        }
        li {
            text-align: left;
            height: 46px;
            line-height: 46px;
            font-size: 14px;
            width: 100%;
            /*float: left;*/
            span{
                padding:0px 5px;
                color:$ns-text-color-black;
            }
            span.red{
                color:$base-color;
            }
        }
    }

    .ranking-wrap{
        width: 1210px;
        display: block;
        margin:20px auto 0px;
        background-color:#fff;
        border-radius: 8px;
        position: relative;
    }

    .ranking-wrap .el-col-12.textLeft{text-align: left}
    .ranking-wrap .ranking-content{
        padding: 0px 20px;
        display: block;
        position: relative;
        height: 460px;
        overflow: hidden;
        .c-left{position: relative;width: 420px;height:100%;float: left;}
        .c-right{position: relative;width: 710px;height:100%;float: right;}
        .c-line{border-left:1px solid #EAE8E8;height:420px;position: absolute;left:460px;top:10px}
        .clear{clear: bottom;zoom: 1;}
        .ranking-header{
            padding:12px 30px 8px;
            border-radius: 8px;
            background: $base-color url("../../../assets/images/nationwide/rank_bg.png") no-repeat center center;
            color:#fff;
            p{
                padding-top: 6px;
                font-size: 12px;
                line-height: 16px;
            }
            .price{
                font-size: 48px;
                line-height: 68px;
            }
        }
        .title{font-size: 15px;font-weight: 700;text-align: center;margin:15px auto 5px;}
    }

    .ranking-wrap .ranking-list{
        width:100%;
        display: block;
        margin:0px;
    }

    .ranking-list.el-tabs> .el-tabs__header .el-tabs__nav{
        width: 68%;
        border:none;
        display: flex;
        margin: 0 16%;
    }
    .ranking-list.el-tabs> .el-tabs__header .el-tabs__item{
        width: 33.33%;
        height: 43px;
        color:#999;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        line-height: 42px;
    }
    .el-tabs__nav-wrap::after{background-color: #fff;}
    .ranking-list.el-tabs> .el-tabs__header .el-tabs__item.is-active{
        color: $base-color;
    }
    .ranking-wrap .ranking-body{padding:0px 35px 20px;position: relative}
    .ranking-wrap .ranking_table{
        position:relative;
        height:370px;
        .ranking_table_head{
            height:30px;
            color: $ns-text-color-black;
            line-height: 30px;
            text-align: center;
            font-size: 15px;
            font-weight: 600;
            background-color: #fff;
        }
        .ranking_table_box{
            height:312px;
            overflow:hidden;
            position: relative;
            text-align: center;
            line-height: 200px;
            font-size: 15px;
            border:none;

            .ranking_table_body{
                height:40px;
                line-height: 40px;
                font-size: 14px;
                .num{
                    width: 100%;
                    line-height: 25px;
                    height: 25px;
                    text-align: left;
                    border-radius: 7px;
                    font-weight: 700;
                    color: #777;
                    margin: 7px auto 0;
                    padding-left: 12px;
                    background-color: transparent;
                }
                .numIcon{
                    text-align: left;
                    img{width: 34px;height: 34px;}
                }
                .ellipsis {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

            }
            .ranking_table_body:nth-child(even){
                background-color: #fff;
            }
        }
    }
    .ranking-wrap .ranking_empty{
        height:200px;
        overflow:hidden;
        position: relative;
        text-align: center;
        line-height: 200px;
        font-size: 15px;
    }
    .ranking-wrap .ranking_bottom{
        position:absolute;
        width:100%;
        height:30px;
        right:0px;
        bottom:-10px;
        line-height:30px;
        font-size:16px;
        color:$ns-text-color-black;
    }
    .ranking-wrap .rankingFilter__items{
        background: #fff;
        margin: 0 0 28px 0;
        padding: 0px 10px;
        height: 18px;
        position: relative;
    }
    .ranking-wrap .rankingFilter__items--label{
        float: left;
        margin-right: 10px;
        margin-left: 10px;
        color: #666;
        width: 40px;
    }
    .ranking-wrap .rankingFilter__items--content {
        float: left;
        width: 600px;
        margin-bottom: 20px;
    }
    .ranking-wrap .rankingFilter__items--content .items {
        display: inline-block;
        padding: 0 10px;
        margin-bottom: 5px;
        cursor: pointer;
        color:#999;
    }
    .ranking-wrap .rankingFilter__items--content .items:hover,
    .ranking-wrap .rankingFilter__items--content .items.active {
        color: $base-color;
    }
    .ranking-wrap .ranking_bottom_text{
        font-size: 16px;
        color:#666;
    }
    .ranking-wrap .more{
        text-align:right;
        margin-top:10px;
        padding-right: 0px;
        font-size: 14px;
        img{margin-left: 15px;}
    }
    /*排行榜end*/
</style>
