<template>
    <div>
        <div class="header-top">
            <div class="top-content">
                <div class="top-left">
                    <a @click="goHome" style="margin-right:10px;cursor:pointer"
                        ><span><i class="n el-icon-s-home"></i>首页</span></a
                    >
                    <a v-if="siteInfo.web_phone1" style="cursor: default;color:#d81e01">{{ siteInfo.web_phone1 }}</a>
                </div>
                <!--                <div class="top-left" v-if="addonIsExit.city">-->
                <!--                    <span>-->
                <!--                        <i class="el-icon-location"></i>-->
                <!--                        <template v-if="city && city.title">-->
                <!--                            {{ city.title }}-->
                <!--                        </template>-->
                <!--                        <template v-else>-->
                <!--                            全国-->
                <!--                        </template>-->
                <!--                    </span>-->
                <!--                    <el-tag size="mini" effect="plain" type="info" class="change-city" @click="$router.push('/change_city')">切换城市</el-tag>-->
                <!--                </div>-->
                <div class="top-right">
                    <router-link v-if="!logined" to="/login" class="ns-text-color">您好，请登录</router-link>
                    <div v-if="logined" class="member-info">
                        <router-link to="/member/index">{{ member.nickname || member.username }}</router-link>
                        <span @click="logout">退出</span>
                    </div>
                    <router-link v-if="!logined" to="/register">免费注册</router-link>
<!--                    <router-link to="/member/order_list">我的订单</router-link>
                    <router-link to="/member/footprint">我的足迹</router-link>
                    <router-link to="/member/collection">我的关注</router-link>-->

                    <router-link to="/cms/help">帮助中心</router-link>
                    <!--					<router-link to="/cms/notice">公告</router-link>-->
                    <a href="/shop/login/login.html">商家登录</a>
                    <el-dropdown v-if="qrcode">
                        <span class="el-dropdown-link">
                            手机商城
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown" v-if="isWebsite">
                            <el-dropdown-item>
                                <div class="mobile-qrcode" style="width:120px"><img :src="website_qrcode" style="width:120px" /></div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                        <el-dropdown-menu slot="dropdown" v-else>
                            <el-dropdown-item>
                                <div class="mobile-qrcode" style="width:120px"><img :src="$img(qrcode)" style="width:120px" /></div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>

        <el-dialog title="消费券激活提醒" :visible.sync="VoucherActivatedShow" width="30%" >
            <div>
                您好！您有 <b class="ns-text-color">{{VoucherCount}}</b> 张待激活的消费券，是否前去激活？
            </div>
            <div slot="footer" style="">
                <el-button @click="noActivation">暂不激活</el-button>
                <router-link :to="{ path: '/member/myCoupon' }" class="el-button">立即激活</router-link>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import { getToken } from "@/utils/auth"
    import { websiteDetail } from "@/api/goods/goods"
    import { waitActivate } from "@/api/auth/login"
    import Cookies from "js-cookie"
    import Config from "@/utils/config";

    export default {
        props: {},
        data: () => {
            return {
                VoucherCount:0,
                VoucherActivatedShow:false,
                isWebsite: false, // 判断是否为分站
                website_qrcode: "" // 分站二维码
            }
        },
        created() {
            this.$store.dispatch("site/qrCodes")
            if (getToken() !== undefined) this.$store.dispatch("member/member_detail")
            this.$store.dispatch("site/defaultFiles")
            this.$store.dispatch("site/addons")
            this.getWebSiteInfo()
            //24小时清除
            setInterval("localStorage.removeItem('CouponReminder')", 6000*24*60);
            //每隔多久弹出一次
            if(this.$route.path != "/member/myCoupon" && this.logined) {
                this.verifyWaitActivate()
            }
        },
        mounted() {},
        watch: {},
        methods: {
            noActivation(){
                this.VoucherActivatedShow = false
                localStorage.setItem('CouponReminder','1')
            },
            verifyWaitActivate(){
                if(!localStorage.getItem('CouponReminder')) {
                    waitActivate().then(res => {
                        if (res.code == 0 && res.data > 0) {
                            this.VoucherCount = res.data
                            this.VoucherActivatedShow = true
                        }
                    })
                    .catch(err => {
                        this.loading = false
                        this.$message.error(err.message)
                    })
                }
            },
            logout() {
                this.$store.dispatch("member/logout")
                vue.$router.push(`/login?redirect=${encodeURIComponent(vue.$router.history.current.fullPath)}`)
            },
            goHome() {
                // this.$router.push("/");

                let currPath = Cookies.get("indexDetail_web_domain")
                //let publicPath = process.env.VUE_WEB_PATH

                // 需要校验的url路径 需要强制跳转的判断页面
                let isChangeingUrlLists = ["http://" + currPath +Config.publicPath+ "/index", "https://" + currPath +Config.publicPath+ "/index", "http://" + currPath + Config.publicPath, "https://" + currPath +Config.publicPath]
                let currHref = window.location.href

                // 当前路径是否包含在校验路径中？
                let ischangeBoolean = false
                isChangeingUrlLists.forEach(element => {
                    if (currHref === element) {
                        ischangeBoolean = true
                    }
                })

                // 兼容从专馆跳转的判断
                if (!ischangeBoolean) {
                    this.$router.push({
                        name: "/",
                        params: {
                            back_index: "back_index"
                        }
                    })
                } else {
                    this.$router.push("/")
                }
            },
            // 获取分站信息
            getWebSiteInfo() {
                let website_id = Cookies.get("website_id")
                let website_type = Cookies.get("website_type")
                // 当前分站是除城市分站以外的时
                if (parseInt(website_id) > 0 && parseInt(website_type) > 0) {
                    websiteDetail({ site_id: website_id })
                        .then(res => {
                            if (res.code == 0) {
                                this.website_qrcode = res.data.web_qrcode
                                this.isWebsite = true
                            }
                        })
                        .catch(err => {
                            this.loading = false
                            this.$message.error(err.message)
                        })
                } else {
                    this.isWebsite = false
                }
            }
        },
        components: {},
        computed: {
            ...mapGetters(["wapQrcode", "member", "addonIsExit", "city", "siteInfo"]),
            qrcode: function() {
                return this.wapQrcode === "" ? "" : this.wapQrcode.path.weapp.img
            },
            logined: function() {
                return this.member !== undefined && this.member !== "" && this.member !== {}
            }
        }
    }
</script>

<style scoped lang="scss">
    .el-dropdown-menu__item {
        padding: 0px 10px;
    }
    .header-top {
        width: 100%;
        height: 35px;
        font-size: 13px;
        background-color: #f5f5f5;

        .el-dropdown {
            font-size: $ns-font-size-sm;
        }
        .top-content {
            width: $width;
            height: 100%;
            margin: 0 auto;
            .top-left {
                height: 100%;
                float: left;
                height: 100%;
                line-height: 35px;
                font-size: 13px;
                .change-city {
                    cursor: pointer;
                    margin-left: 5px;
                    &:hover {
                        color: $base-color-f;
                        border-color: $base-color;
                    }
                }
            }
            .top-right {
                height: 100%;
                float: right;
                font-size: $ns-font-size-sm;
                color: $ns-text-color-black;
                a {
                    float: left;
                    line-height: 35px;
                    font-size: 13px;
                    padding: 0 10px;
                    &:hover {
                        color: $base-color;
                    }
                }
                div {
                    float: left;
                    height: 100%;
                    margin-left: 10px;
                    cursor: pointer;
                    line-height: 35px;
                    padding: 0 5px;
                    font-size: 13px;

                    &.member-info {
                        margin-left: 0;
                        span:first-child {
                            margin-right: 10px;
                        }
                    }
                    span:hover {
                        color: $base-color-nav;
                    }
                    &.el-dropdown:hover {
                        background-color: $base-color-bg;
                    }
                }
            }
        }
    }
    .mobile-qrcode {
        padding: 10px 0;
    }
    
</style>
