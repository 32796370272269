var vueConfig=require('../../vue.config')

export default {
	
	// api请求地址
	baseUrl: process.env.VUE_APP_DOMAIN,
	mealplanPid: process.env.VUE_APP_MEALPLAN_PID,
	localLacma: process.env.VUE_APP_LOCAL_LACMA,
	webSocket: process.env.VUE_APP_WSS,

	publicPath:vueConfig.publicPath,

	// 图片域名
	imgDomain: '',
	
	// 腾讯地图key
	mpKey: '',
	
	// api安全
	apiSecurity: "",
	
	// 公钥
	publicKey: ``,

	//店铺默认banner
	shopDefaultBanner:"../assets/images/shop/img_"+process.env.VUE_APP_NAME+".png"
}

