import regionLayout from "../../layout/region"


const regionRoutes = [
    {
        path: "/region-*",
        name: "region_index",
        meta: {
            module: "shop",
            backgroundColor: "#fff"
        },
        component: () => import("@/views/region/index")
    },
    {
        path: "/region_list",
        name: "region_list",
        meta: {
            module: "shop"
        },
        component: () => import("@/views/region/list")
    }
]
export default {
    path: "/region",
    component: regionLayout,
    redirect: "/region",
    name: "regionIndex",
    children: [...regionRoutes]
}
