/**
 * Created by llj on 2021年3月9日16:38:56.
 * email just05075@foxmail.com
 */

const path = require('path');
const Timestamp = new Date().getTime();

function resolve(dir) {
    return path.join(__dirname, dir);
}

module.exports = {
    configureWebpack: { // webpack 配置
        output: { // 输出重构  打包编译后的 文件名称  【模块名称.版本号.时间戳】
            filename: `assets/js/[name].${process.env.VUE_APP_VERSION}.${Timestamp}.js`,
            chunkFilename: `assets/js/[name].${process.env.VUE_APP_VERSION}.${Timestamp}.js`
        },
    },
    //publicPath: process.env.NODE_ENV === 'production'?'/webrate':'/',  //测试抽佣模式
    publicPath: process.env.NODE_ENV === 'production'?'/web':'/',
    // outputDir: resolve('../local_fupingadmin/web'),
    assetsDir: "assets",
    chainWebpack: config => {
        const oneOfsMap = config.module.rule("scss").oneOfs.store
        oneOfsMap.forEach(item => {
            item.use("sass-resources-loader")
                .loader("sass-resources-loader")
                .options({
                    // 全局变量资源路径
                    resources: "./src/assets/styles/main.scss"
                    // 也可以选择全局变量路径数组, 如果你有多个文件需要成为全局,就可以采用这种方法
                    // resources: ['./path/to/vars.scss', './path/to/mixins.scss']
                    // 或者你可以将多个scss文件@import到一个main.scss用第一种方法，都是可以的
                })
                .end()
        })
    },
    devServer: {
        proxy: 'https://www.hnxfbfw.com/'
    }
}

