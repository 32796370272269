import Vue from "vue"
import VueRouter from "vue-router"
import Lang from "../utils/lang.js"
import Config from "../utils/config.js"
import NProgress from "nprogress"
import "nprogress/nprogress.css"
import indexRoutes from "./modules/index"
import diyRoutes from "./modules/diy"
import notnavRoutes from "./modules/notnav"
import memberRoutes from "./modules/member"
import shopRoutes from "./modules/shop"
import supportRoutes from "./modules/support"
import regionRoutes from "./modules/region"
import authRoutes from "./modules/auth"
import { getToken } from "../utils/auth"
import Cookies from 'js-cookie'
import store from "../store"
import { onHostWebsiteDetail } from "@/api/goods/goods"
import { removeCookiess } from "@/components/cookies_fun"
import floorStyle1 from "@/views/index/components/floor-style-1";
import floorStyle2 from "@/views/index/components/floor-style-2";
import floorStyle3 from "@/views/index/components/floor-style-3";
import rankingStyle1 from "@/views/index/components/ranking-style-1";
import rankingStyle3 from "@/views/index/components/ranking-style-3";
import localbranchStyle1 from "@/views/index/components/localbranch-style-1";
import localbranchStyle2 from "@/views/index/components/localbranch-style-2";

// 屏蔽跳转到本身时的报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    if (location && typeof location == "string" && location.indexOf("http") != -1) return window.location.open(location, "_self")

    return originalPush.call(this, location).catch(err => err)
}

const originalResolve = VueRouter.prototype.resolve
VueRouter.prototype.pushToTab = function pushToTab (location) {
    if (!location) return;
    if (location && typeof location == "string" && location.indexOf("http") != -1) return window.open(location, "_blank")

    const { href } = originalResolve.call(this, location)
    window.open(href, "_blank")
}

const cookiesDays = Vue.prototype.$cookiesDays;

Vue.use(VueRouter)

/**
 * meta参数解析
 * module: 所属模块，目前用于寻找语言包
 * module: 菜单所属模块
 * menu: 所属菜单，用于判断三级菜单是否显示高亮，如菜单列表、添加菜单、编辑菜单都是'menu'，用户列表、添加用户、编辑用户都是'user'，如此类推
 */
const mainRouters = [
    indexRoutes,
    diyRoutes,
    notnavRoutes,
    authRoutes,
    memberRoutes,
    shopRoutes,
    supportRoutes,
    regionRoutes,
    {
        path: "/closed",
        name: "closed",
        meta: {
            module: "index"
        },
        component: () => import("@/views/index/closed.vue")
    },
    {
        path: "*",
        name: "error",
        meta: {
            module: "index"
        },
        component: () => import("@/views/index/error.vue")
    }
]

const router = new VueRouter({
    mode: 'history',
    base: Config.publicPath+'/',
    routes: mainRouters
});

// 根据域名获取分站信息
function getWebsiteDetail () {
    let urlhost = window.location.host;
    return new Promise((resolve, reject) => {
        onHostWebsiteDetail({ web_domain: urlhost })
            .then(res => {
                if (res.code == 0 && res.data) {
                    resolve(res.data);
                } else {
                    resolve(null);
                }
            })
            .catch(err => {
                this.$message.error(err.message)
            })
    })
}

// 获取主站信息
function getWebsiteInfoIndex () {
    return new Promise((resolve, reject) => {
        onHostWebsiteDetail({ site_id: 0 })
            .then(res => {
                if (res.code == 0 && res.data) {
                    resolve(res.data);
                } else {
                    resolve(null);
                }
            })
            .catch(err => {
                this.$message.error(err.message)
            })
    })
}

// 获取信息，有信息时写入到cookies
async function changeHost (to, from, next) {
    // 获取主站信息
    let indexDetail = await getWebsiteInfoIndex();
    if (indexDetail && indexDetail.web_domain) {
        Cookies.set("indexDetail_web_domain", indexDetail.web_domain, { expires: cookiesDays })
    }
    // 获取当前站信息
    // 写入cookies：分站详情
    let cityDetail = await getWebsiteDetail();
    if (cityDetail && cityDetail.site_id > 0) {
        // ...当前是分站，写入cookies...
        // 写入cookies：分站详情
        Cookies.set("cityDetail", cityDetail, { expires: cookiesDays })
        // 写入cookies：分站是否允许跳转到首页
        Cookies.set("is_back_index", cityDetail.is_back_index, { expires: cookiesDays })
        // 写入cookies：分站城市的地理id，如长沙（430100000000）
        Cookies.set("site_area_id", cityDetail.site_area_id, { expires: cookiesDays })
        Cookies.set("website_id", cityDetail.site_id, { expires: cookiesDays })
        Cookies.set("website_type", cityDetail.type, { expires: cookiesDays })
    } else {
        // ...当前是主站，清空cookies..
        removeCookiess();
    }

    // 执行路由正常程序
    beforeAll(to, from, next);
}

/**
 * 执行路由正常程序
 * @param {*} to 
 * @param {*} from 
 * @param {*} next 
 */
function beforeAll (to, from, next) {
    if (store.getters.siteInfo && !store.getters.siteInfo.web_status && to.path != "/closed") {
        return next("/closed")
    }

    // 如果没有登录，跳转到登录，并记录当前页面路径
    if (to.meta.auth) {
        const token = getToken()
        if (!token) {
            return next(`/login?redirect=${encodeURIComponent(to.fullPath)}`)
        }
    }


    // ** begin **********************************************************
    // 新增判断分站相关逻辑（分站禁止跳出到首页、单位馆无权限强制跳转首页等）
    // ** begin **********************************************************
    // ** 1 **********************************************************

    // 写入分站的是否允许跳出
    let is_back_index = parseInt(Cookies.get('is_back_index'));
    let website_id = parseInt(Cookies.get('website_id'));
    let website_type = parseInt(Cookies.get('website_type'));

    /**
     * 判断是否要返回首页
     * params[back_index:back_index];
     * 当点击返回首页，并且分站允许返回首页时，直接跳转到总站首页。
     */
    if (to.params.back_index && to.params.back_index === 'back_index' && is_back_index === 1) {

        Cookies.remove("website_id");
        Cookies.remove("website_type");
        Cookies.remove("is_back_index");
        let indexDetail_web_domain = Cookies.get('indexDetail_web_domain');

        // 跳转到总站首页
        window.location.href = 'http://' + indexDetail_web_domain + "?back_index_gohome=back_index_gohome";
        return;

    } else if ((to.path === '/index' || to.path === '/') && to.params.back_index && to.params.back_index === 'back_index') {

        // 进入首页
        return next('/');

    } else if (parseInt(website_id) > 0) {

        // 不管分站中设置的是否允许跳转首页是允许否，都先进入分站去。
        if (is_back_index === 2 || is_back_index === 1) {

            // 需要校验的url路径 需要强制跳转的判断页面
            let isChangeingUrlLists = [
                '/',  // 默认
                '/index', //首页
                '/promotion/special', // 重点帮扶页
                '/promotion/optimalfarmers', // 优家中心
            ];
            // 当前路径是否包含在校验路径中？
            let ischangeBoolean = false;
            isChangeingUrlLists.forEach(element => {
                if (to.path === element) {
                    ischangeBoolean = true;
                }
            });
            if (ischangeBoolean && parseInt(website_id) > 0) {
                // 路由的名称 强制跳转
                if (website_type === 1) {
                    return next('/citylist');
                } else {
                    return next('/special');
                }
            }
        }
    }

    window.document.body.style.backgroundColor = to.meta.backgroundColor || ""
    NProgress.start()
    next()
}



// 路由守卫，控制访问权限
router.beforeEach((to, from, next) => {
    changeHost(to, from, next);
    // 执行路由正常程序
    // beforeAll(to, from, next);
})

router.afterEach((to, from) => {
    const title = Lang.getLangField("title", to)
    const metaTitle = store.getters.siteInfo.title || "GDJT"
    window.document.title = `${title} - ${metaTitle}`
    setTimeout(() => {
        if (document.getElementsByClassName("el-main").length) {
            if (to.meta.mainCss) {
                for (let field in to.meta.mainCss) {
                    document.getElementsByClassName("el-main")[0].style[field] = to.meta.mainCss[field]
                }
            } else {
                document.getElementsByClassName("el-main")[0].style = ""
            }
        }
    }, 100)
    NProgress.done()
})

export default router
