<template>
    <el-container>
        <!-- 顶部条 -->
        <div class="banner top-banner" v-loading="loadingAd" v-if="is_show">
            <el-carousel height="70px" arrow="hover" direction="vertical" indicator-position="none">
                <el-carousel-item v-for="item in adList" :key="item.adv_id" :style="{ backgroundColor: item.background }">
                    <el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" />
                </el-carousel-item>
            </el-carousel>
            <i class="el-icon-circle-close" @click="closeAd"></i>
        </div>

        <!-- 头部 -->
        <el-header :class="[navIsShow == true ? 'navIsShow' : 'navIsHide']"><ns-header /></el-header>

        <div width="1300px">
            <transition name="slide"><router-view /></transition>
            <!-- 右侧栏 -->
            <ns-aside />
        </div>

        <!-- 底部 -->
        <el-footer>
            <newFooter></newFooter>
        </el-footer>
    </el-container>
</template>
<script>
    import NsHeader from "./components/NsHeader"
    // import NsFooter from "./components/NsFooter"
    import NsAside from "./components/NsAside"
    import newFooter from "./components/newFooter"
    import { adList } from "../api/website"
    import Cookies from "js-cookie"

    export default {
        name: "Layout",
        components: {
            NsHeader,
            // NsFooter,
            NsAside,
            newFooter
        },
        created() {
            //站点顶部条
            this.getAdList()
            this.getWebSiteInfo()
        },
        data: () => {
            return {
                navIsShow: true, //是否显示navlistr的高度
                loadingAd: true,
                adList: [],
                is_show: true,
                indexTopAdNum: 0
            }
        },
        mounted() {},
        computed: {},
        watch: {},
        methods: {
            // 获取分站信息
            getWebSiteInfo() {
                let website_id = Cookies.get("website_id")
                let website_type = Cookies.get("website_type")
                // 当前分站是分站时
                if (website_id && parseInt(website_type) > 1) {
                    this.navIsShow = false
                } else {
                    this.navIsShow = true
                }
            },
            getAdList() {
                if (this.$store.state.app.indexTopAdNum >= 3) {
                    this.loadingAd = false
                    this.is_show = false
                    return
                }
                adList({ keyword: "NS_PC_INDEX_TOP" })
                    .then(res => {
                        this.adList = res.data.adv_list
                        for (let i = 0; i < this.adList.length; i++) {
                            if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
                        }
                        this.loadingAd = false

                        //控制显示隐藏
                        if(res.data.adv_position.is_state == 0) {
                            this.is_show = false
                        }
                    })
                    .catch(err => {
                        this.loadingAd = false
                    })
            },
            closeAd() {
                this.is_show = false
                this.indexTopAdNum = this.$store.state.app.indexTopAdNum
                this.indexTopAdNum++
                this.$store.commit("app/SET_INDEXTOPADNUM", this.indexTopAdNum)
            }
        }
    }
</script>
<style lang="scss" scoped>
    .top-banner .el-image{width: 100%;height: 70px}
    #app .el-header {
        margin: 0 auto;
        padding: 0;
    }
    #app .header {
        height: 192px !important;
    }
    .banner {
        text-align: center;
        height: 70px;
        position: relative;
        i {
            font-size: 30px;
            position: absolute;
            z-index: 100;
            right: 50px;
            top: 8px;
            cursor: pointer;
            color: #d4d4d4;
        }
    }
    .el-footer {
        padding: 0;
        height: auto !important;
        background-color: #fff;
        /*padding-top: 45px;*/
    }

    // 是否显示navlistr的高度：不显示高度，
    .navIsShow {
        height: 212px !important;
    }
    .navIsHide {
        height: 145px !important;
    }
</style>
