<template>
  <div class="customer-panel">
    <ul>
      <li v-for="(item, index) in customerList" :key="index">
        <div id="customer">
          <img
            src="https://group-image.sdongpo.com/groupdev/upload_pic/com_thumb_20200722152445f0e67d3f5f17e9bd7ca58.png"
            alt=""
            width="100"
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      customerList: [
        {
          name: '在线咨询',
        },
      ],
    };
  },
  created() {
  },
  methods: {},
};
</script>

<style lang="scss">
.customer-panel {
  position: fixed;
  bottom: 20px;
  right: 50%;
  margin-right: -705px;
  color: white;
}
</style>
