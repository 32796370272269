import Cookies from 'js-cookie'

// 判断是否强制跳转
export function checkCookies () {
    // is_jump_out: 2
    // 是否强制跳转：1：强制跳出；2：允许访问本站；  不允许时强制跳转到主站
    let login_is_jump_out = Cookies.get("login_is_jump_out")
    if (parseInt(login_is_jump_out) == 1) {
        removeCookiess();
        // 跳转到首页
        this.$router.push('/index')
    }
}

// 清空cookies
export function removeCookiess () {
    // ...cookies_fun... 清空cookies ...
    Cookies.remove("login_is_jump_out");
    Cookies.remove("login_is_site");
    Cookies.remove("website_id");
    Cookies.remove("website_type");
    Cookies.remove("is_back_index");
    Cookies.remove("site_area_id");
    Cookies.remove("cityDetail");
}
